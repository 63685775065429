import request from '../../utils/request'
interface Item<T> {
  checkItemName?: T;
  checkItemType?: T;
  diseaseId?: T;
  size: number;
  current: number;
}
//获取项目数据
export function getItemData(data: Item<string>): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/checkItem/list',
    type: 'params',
    data
  })
}
//获取项目数据(包含折后价)
export function getItems(data: Item<string>): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/checkItem/getItems',
    type: 'params',
    data
  })
}

//修改折扣
export function updateDiscount(data: Item<string>): Promise<any> {
  return request({
    method: 'POST',
    url: 'manage/checkItem/updateDiscount',
    type: 'params',
    data
  })
}

//根据项目节点id进行删除操作
export function deleteItem(ids: number[]) {
  return request({
    url: 'manage/checkItem/remove?ids=' + ids.join(','),
    method: 'POST',
    type: 'params'
  })
}
//根据项目备注和项目说明
export function updateItem(data: any) {
  return request({
    url: 'manage/checkItem/update',
    method: 'POST',
    data: data,
  })
}
//获取分类列表
export function getRecommendTypeList(): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/checkItemCategory/getRecommendTypeList',
    type: 'params',
  })
}


